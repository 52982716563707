const data = [
  {
    id: 1,
    section: ["Public homepage"],
    content: [
      "Discover the best spectacle lenses for you",
      "Want to learn more about your vision needs? Take out lifestyle questionnaire and find your perfect spectacle lens solutions.",
      "Questionnaire",
    ],
  },
  {
    id: 2,
    section: ["Login"],
    content: ["ECP login", "Login", "Remember me", "Request Account"],
  },
  {
    id: 3,
    section: ["Ecp's Homepage"],
    content: ["Scan QR", "Compare Lenses", "Lens Simulation"],
  },
  {
    id: 4,
    section: ["Login page"],
    content: [
      "Login",
      "Remember me",
      "Request account",
      "Password",
      "Enter Password",
      "Nickname",
      "Enter Nickname",
      "Back to password",
    ],
  },
  {
    id: 5,
    section: ["Packages"],
    content: ["Lens Selector", "Document Library", "Languages", "Log out"],
  },
  {
    id: 6,
    section: ["Public Recommendation page"],
    content: [
      "Your Lens Recommendation",
      "Email me my results!",
      "1. Your unique QR code and results will be sent to your email address.",
      "2. Book an appointment through the email and bring these results to your next vision consultation.",
      "3. Present the QR code in-store and get a personalized lenswear solution that is truly made for yours needs.",
      "Learn",
    ],
  },
  {
    id: 7,
    section: ["ECP's Recommendation page"],
    content: ["Name's Lens Recommendation", "Lens Confirmation"],
  },
  {
    id: 8,
    section: ["ECP's edit Recommendation page"],
    content: [
      "Change Recommendation",
      "Save",
      "Cancel",
      "Back to Vision Needs",
    ],
  },
  {
    id: 9,
    section: ["Prescription page"],
    content: [
      "PRESCRIPTION",
      "*Fields marked with an asterisk are mandatory",
      "Begin",
      "Submit",
      "THIS CANNOT BE CHANGED ONCE SUBMITTED",
    ],
  },
  {
    id: 10,
    section: ["Questionnaire edit page"],
    content: ["QUESTIONNAIRE"],
  },
  {
    id: 11,
    section: ["Compare Lens page"],
    content: [
      "RECOMMENDATION",
      "LEARN MORE",
      "Simulate Selected Lenses",
      "Vision",
      "Near",
      "Intermedia",
      "Far",
      "Back to Lens Recommendation",
    ],
  },
  {
    id: 12,
    section: ["Lens Simulation page"],
    content: [
      "Lens Simulation",
      "Please select the chosen solution(s).",
      "Confirm",
      "BOTH",
      "WIREFRAME",
      "MATCHING",
    ],
  },
  {
    id: 13,
    section: ["Lens Confirmation page"],
    content: [
      "Lens Confirmation",
      "Please select the chosen solution(s).",
      "Confirm",
    ],
  },
  {
    id: 14,
    section: ["Summary page"],
    content: [
      "Name's Summary",
      "Save to gallery",
      "Send to customer",
      "End Session",
      "Screenshot saved to gallery",
    ],
  },
  {
    id: 15,
    section: ["Pop-up message"],
    content: ["Send production information to Name via:", "EMAIL:"],
  },
  {
    id: 16,
    section: ["Pop-up messages"],
    content: [
      "Your current session is in progress",
      "To finish your current session and save your data, please progress to the 'Lens Confirmation' page",
      "Continue session",
      "Start new session",
    ],
  },
];

export default data;
