<template>
  <div>
    <confirm-modal ref="confirmModal" />
    <div class="common-department">
      <!-- sticky action menu bar -->
      <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
        <div class="user-infor">
          <span>{{ countryAndLanguage }}</span>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light topnav">
          <div id="navbarText" :class="getTableHeaderClass + ' collapse navbar-collapse'">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <router-link
                  exact
                  class="nav-link pr-0 pl-0"
                  to="/other/buttons-texts"
                >
                  Buttons/Texts
                </router-link>
              </li>
            </ul>
            <!------------- edit range-------------------------->
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <template v-if="!editable">
                  <b-button
                    variant="outline-info custom-btn"
                    @click="turnOnEditable()"
                  >
                    Edit content
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    variant="outline-info custom-btn btn-save"
                    @click="saveAll()"
                  >
                    Save
                  </b-button>
                  <b-button
                    variant="outline-info custom-btn"
                    @click="turnOffEditable()"
                  >
                    Cancel
                  </b-button>
                </template>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <router-view />
      <!-- Space end page -->
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
addPropertyData, changePropertyData, MODAL_CANCEL, MODAL_SAVE, OK_CONFIRM, reformatData
} from "../../common/CommonUtils";
import OtherService from "../../service/other/OtherService";
import ConfirmModal from "../common/ConfirmModal.vue";

export default {
  components: {
    ConfirmModal,
  },
  data() {
    const surveyId = this.$store.state.survey.id;
    return {
      surveyId: surveyId <= 0 ? 1 : surveyId,
      editable: (this.$store.state.survey.isSurveyEditing = false) && false
    };
  },
  computed: {
    // editable() {
    //    return this.$store.state.other.editStatus;
    // },
    countryAndLanguage() {
      return this.$store.state.survey.countryAndLanguage;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "action-menu-edit"
            : ""
    },
  },
  beforeCreate() {
    const _this = this;
    let surveyId = this.$store.state.survey.id;
    surveyId = surveyId <= 0 ? 1 : surveyId;
    let loader = this.$loading.show();

    // _this.$store.dispatch("other/initialOtherData", {
    //   data: OtherService.getOtherList(surveyId),
    // });

    OtherService.getOtherList(surveyId).then((res) => {
      _this.$store.dispatch("other/initialOtherData", {
        data: res,
      });
      loader.hide();
    });

    // OtherService.checkRemoveSurvey(surveyId).then((res) => {
    //   console.log("res", res);
    //   this.$store.commit("other/changeIsRemoveSurveyStatus", {
    //     data: res,
    //   });
    //   loader.hide();
    // });

    OtherService.getCountryEmailFlags(surveyId).then((res) => {
      console.log("res", res);
      this.$store.commit("other/changeIsRemoveSurveyStatus", {
        data: res.emailCollectionAccess,
      });
      this.$store.commit("other/changeIsStoreEmail", {
        data: res.storeEmail,
      });
      loader.hide();
    });

    this.$store.commit("other/changeEditStatus", {
      data: false,
    });
  },
  methods: {
    changeEditableState(state) {
      this.editable = state;
      this.$store.state.survey.isSurveyEditing = state;
      if (state === true) {
        this.$store.state.survey.editingScreenSaveFunc = this.saveAll;
      }
    },
    // eslint-disable-next-line no-unused-vars
    clickImage($event) {
      alert("do nothing");
    },
    turnOnEditable() {
      this.$store.commit("other/changeEditStatus", {
        data: true,
      });
      this.changeEditableState(true)

      const otherData = _.cloneDeep(this.$store.state.other.otherData);
      const editData = changePropertyData(otherData, {
        propertyName: "isEdit",
        value: true,
      });
      this.$store.commit("other/changeOtherData", {
        data: editData,
      });
    },

    turnOffEditable() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        if (data === OK_CONFIRM) {
          const otherData = _.cloneDeep(
            this.$store.state.other.otherOriginData
          );
          this.$store.commit("other/changeOtherData", {
            data: addPropertyData(otherData, {
              propertyName: "isEdit",
              value: false,
            }),
          });

          this.$store.commit("other/changeEditStatus", {
            data: false,
          });
          this.changeEditableState(false);
        }
      });
    },

    saveEdit(chainFunc) {
      this.$store.commit("other/changeEditStatus", {
        data: false,
      });

      this.changeEditableState(false);

      const otherData = _.cloneDeep(this.$store.state.other.otherData);
      const editDataUI = changePropertyData(otherData, {
        propertyName: "isEdit",
        value: false,
      });
      this.$store.commit("other/changeOtherData", {
        data: editDataUI,
      });

      const originĐata = this.$store.state.other.otherOriginData;
      const getReformatData = reformatData(originĐata, editDataUI, "data");
      // console.log("getReformatData", JSON.stringify(getReformatData[2]));
      // call api
      let loader = this.$loading.show();
      const response = OtherService.updateOther(this.surveyId, getReformatData);
      //update to origin
      response &&
        response.then((res) => {
          loader.hide();
          this.$store.dispatch("other/initialOtherData", {
            data: res,
          });
          if (chainFunc !== undefined)
            chainFunc()
        }).catch((e) => {
          loader.hide();
          console.error(e);
        });
    },

    saveAll(chainFunc) {
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          this.saveEdit(chainFunc);
        }
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}

.nav-link {
  font-size: 20px;
}

@media (min-width: 576px) {
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}
</style>
