<template>
  <div v-if="isShowModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content custom-dialog">
              <div class="modal-body">
                <div class="body-text-modal">
                  <p style="white-space: pre-line;">
                    {{ modalTitle }}
                  </p>
                </div>
                <div class="btn-modal">
                  <button
                    ref="okButton"
                    type="button"
                    class="btn btn-info"
                    style="background:#006DB6; margin-right:20px; width: 127px; height: 40px;"
                  >
                    {{ modalButtonOk }}
                  </button>
                  <button
                    ref="notOkButton"
                    type="button"
                    class="btn btn-light"
                    style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                  >
                    {{ modalButtonNotOk }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  OK_CONFIRM,
  NOT_OK_CONFIRM,
  isNotEmpty,
} from "../../common/CommonUtils";

export default {
  name: "ConfirmModal",
  props: {
    title: {
      type: String,
      default: "All changes will be removed.\nSelect “confirm” to continue.",
    },
    buttonOk: {
      type: String,
      default: "Confirm",
    },
    buttonNotOk: {
      type: String,
      default: "Cancel",
    },
  },
  data: function() {
    return {
      isShowModal: false,
      titleModal: this.title,
      notAcceptButton: this.buttonNotOk,
      acceptButton: this.buttonOk,
    };
  },
  computed: {
    modalTitle: {
      get: function() {
        return this.titleModal;
      },
      set: function(newTitle) {
        this.titleModal = newTitle;
      },
    },
    modalButtonOk: {
      get: function() {
        return this.acceptButton;
      },
      set: function(newValue) {
        this.acceptButton = newValue;
      },
    },
    modalButtonNotOk: {
      get: function() {
        return this.notAcceptButton;
      },
      set: function(newValue) {
        this.notAcceptButton = newValue;
      },
    },
  },
  methods: {
    open(modalData = {}, callback = Function) {
      if (isNotEmpty(modalData.title)) {
        this.modalTitle = modalData.title;
      }

      if (isNotEmpty(modalData.buttonOk)) {
        this.modalButtonOk = modalData.buttonOk;
      }

      if (isNotEmpty(modalData.buttonNotOk)) {
        this.modalButtonNotOk = modalData.buttonNotOk;
      }

      this.isShowModal = true;
      this.$nextTick(function() {
        this.$refs.okButton.addEventListener(
          "click",
          // eslint-disable-next-line no-unused-vars
          (e) => {
            this.isShowModal = false;
            callback(OK_CONFIRM);
          },
          false
        );

        this.$refs.notOkButton.addEventListener(
          "click",
          // eslint-disable-next-line no-unused-vars
          (e) => {
            this.isShowModal = false;
            callback(NOT_OK_CONFIRM);
          },
          false
        );
      });
    },
  },
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.custom-dialog {
  width: 467px;
  height: 283px;
  background: #4b4b4b;
}

.body-text-modal {
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  display: flex;
  justify-content: center;
}
</style>
