/* eslint-disable no-console */
// import _ from 'lodash';
import { isEmpty } from '../../common/CommonUtils';
import { http } from '../../plugin/http2-common';
import OtherMock from './mock/OtherMock';
const OTHER_UPDATE_API = '/other/update/';
// eslint-disable-next-line no-unused-vars
const OTHER_GET_API = '/other/list/';
const CHECK_REMOVE_SURVEY = '/api/survey/check-remove/'
const GET_COUNTRY_EMAIL_FLAGS = '/api/survey/get-country-email-flags/'


/**
 * custom on first version, ....will change many time
 */
const formatOtherResponse = responseData => {
    return responseData.map(question => { 
        return question
    });
}

/**
 * custom on first version, ....will change many time
 */
const formatOtherRequest = (requestData, isUpdateFirstTime = false) => {
    return requestData.map(question => {
        const questionRequest = {...question}
        if(isUpdateFirstTime){
            delete questionRequest.id
        }
        return questionRequest
    });
}

class OtherService {
    static isUpdateFirstTime = false;
    // eslint-disable-next-line no-unused-vars
    getOtherList(surveyId) {
        // console.log("OtherMock", OtherMock)
        // return OtherMock;
        return http
            .get(OTHER_GET_API + surveyId + "?platform=web")
            .then(response => {
                if (isEmpty(response.data.result)) {
                    OtherService.isUpdateFirstTime = true;
                    return OtherMock;
                }
                const resFormat = formatOtherResponse(response.data.result)
                return resFormat
            })
            .catch(err => {
                console.error(err)
            });
    }

    updateOther(surveyId, data) {
        return http
            .put(OTHER_UPDATE_API + surveyId + "?platform=web", formatOtherRequest(data, OtherService.isUpdateFirstTime))
            .then(response => {
                OtherService.isUpdateFirstTime = false;
                return formatOtherResponse(response.data.result);
            })
            .catch(err => {
                console.error(err)
            });
    }

    checkRemoveSurvey(surveyId) {
        return http
            .get(CHECK_REMOVE_SURVEY + surveyId)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.error(err)
            });
    }

    getCountryEmailFlags(surveyId) {
        return http
            .get(GET_COUNTRY_EMAIL_FLAGS + surveyId)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.error(err)
            });
    }

}

export default new OtherService();